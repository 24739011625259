var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1f7fb0afc3fff8137d16f996eb4165efd8de67fd"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";

Sentry.init({
	dsn: "https://2ce1243ebc88c452505ab183001d8948@o4507630038024192.ingest.us.sentry.io/4507630039138304",

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1,
	enabled: process.env.NODE_ENV === "production",
	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	replaysOnErrorSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		posthog.sentryIntegration({
			organization: "shortbread",
			projectId: 4507630039138304,
			severityAllowList: ["info", "error", "fatal"],
		}),
		// Sentry.replayIntegration({
		//   // Additional Replay configuration goes in here, for example:
		//   maskAllText: true,
		//   blockAllMedia: true,
		// }),
	],
});
